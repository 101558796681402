<template>
  <IAIModal
    v-model="isModalOpen"
    :position="isMobile ? 'bottom-center' : 'bottom-right'"
    variant="secondary"
    max-width="30rem"
    persistant
    class="cookie-consent-modal"
  >
    <div class="flex w-96 flex-col gap-6">
      <p class="font-normal leading-tight text-white">
        {{ t("common.modals.cookie-consent.message") }}
      </p>

      <a href="https://imagen-ai.com/privacy-policy/" class="text-white underline">
        {{ t("common.modals.cookie-consent.privacy-policy") }}
      </a>

      <div class="flex gap-4 self-center">
        <IAIButton
          variant="white-border"
          size="large"
          :label="t('common.modals.cookie-consent.reject')"
          @click="handleReject"
        />

        <IAIButton
          variant="primary"
          size="large"
          :label="t('common.modals.cookie-consent.accept')"
          @click="handleAccept"
        />
      </div>
    </div>
  </IAIModal>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { IAIModal, IAIButton } from "@/core-ui-vue";
import { universalStorage } from "@/utils/universal-storage";
import { t } from "@/locales/vue";
import { funnelService } from "@/services/funnelService";

const isModalOpen = ref(false);

const { mobile_or_desktop, country_code } = universalStorage.getAll();

const isMobile = mobile_or_desktop === "mobile";

const consentRequiredCountries = [
  // EU countries
  "BE",
  "BG",
  "CY",
  "DK",
  "DE",
  "EE",
  "FI",
  "FR",
  "GR",
  "HU",
  "IE",
  "IT",
  "HR",
  "LV",
  "LT",
  "LU",
  "MT",
  "NL",
  "AT",
  "PL",
  "PT",
  "RO",
  "SI",
  "SK",
  "ES",
  "CZ",
  "SE",
  // EEA countries (non-EU)
  "IS",
  "LI",
  "NO",
  // Switzerland
  "CH",
  // United Kingdom
  "GB",
];

const handleAccept = () => {
  localStorage.setItem("cookieConsent", "accepted");
  window.clarity("consent");
  funnelService.trackEvent("clarity_consent_accepted");
  isModalOpen.value = false;
};

const handleReject = () => {
  localStorage.setItem("cookieConsent", "rejected");
  funnelService.trackEvent("clarity_consent_rejected");
  isModalOpen.value = false;
};

onMounted(() => {
  const savedConsent = localStorage.getItem("cookieConsent");
  const isConsentRequired = consentRequiredCountries.includes(country_code ?? "");
  isModalOpen.value = !savedConsent && isConsentRequired;
});
</script>
